(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "moment"], factory);
	else if(typeof exports === 'object')
		exports["antd"] = factory(require("vue"), require("moment"));
	else
		root["antd"] = factory(root["Vue"], root["moment"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__676__, __WEBPACK_EXTERNAL_MODULE__3034__) {
return 